import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import Page from '../components/scenes/Page/Page/Page';
import DocumentList from '../components/scenes/DocumentList/DocumentList';
import CATEGORIES from '../constants/categories';
import VERSIONS from '../constants/versions';
import { useTranslation } from 'react-i18next';
import { SEO } from '../components/seo/SEO/SEO';


export default function Documents( { data, pageContext } ) {
  const { t, i18n } = useTranslation( 'common' );
  const { version } = pageContext;
  const category = CATEGORIES.find( settings => settings.category === pageContext.category );
  const isLatest = version === VERSIONS[ 0 ].version;

  const heading = category
    ? category.label
    : ! isLatest ? 'Archives' : 'Documents';

  useEffect( () => {
    if ( ! category && ! isLatest ) {
      const redirect = async () => {
        await navigate( `/${ version }/archives/`, { replace: true } );
      }

      redirect().catch( console.error );
    }
  } );

  return (
    <Page
      heading={ heading }
      isDocument={ false }
      localizedHeading={ category && i18n.language !== 'en' && t( category.category ) }
    >
      <DocumentList docs={ data.allMdx.edges } />
    </Page>
  );
}

// todo optimize
export function Head( { data, pageContext } ) {
  const { t, i18n } = useTranslation( 'common' );
  const category = CATEGORIES.find( settings => settings.category === pageContext.category );
  const { version } = pageContext;
  const isLatest = version === VERSIONS[ 0 ].version;
  const heading = category
    ? category.label
    : ! isLatest ? 'Archives' : 'Documents';
  const localizedHeading = category && i18n.language !== 'en' && t( category.category )

  return (
    <SEO
      title={ localizedHeading || heading }
      isArticle
    />
  );
}

export const query = graphql`
  query($category: String!, $language: String!, $version: String!) {
    allMdx(
      filter: {fields: {category: {eq: $category}, language: {eq: $language}, version: {eq: $version}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          fields {
            category
            slug
            language
            version
          }
          id
          frontmatter {
            title
            localizedTitle
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;